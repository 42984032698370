<template>
    <div class="page">
        <div class="conter w-1350">
            <div class="conter-left">
                <div class="hzycenter-text">
                    <p></p>
                    <span>供求对接</span>
                </div> 
                <div class="nav-list">
                    <div class="nav-item" v-for="(item,index) in listData" :key="index" @click="goTab(item.id)">
                        <div :class="{active:id==item.id}">{{item.name}}</div>
                        <p><i class="el-icon-arrow-right"></i></p>                         
                    </div>
                </div>
            </div>

            <div class="conter-right">
                <div class="hzynav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                        <el-breadcrumb-item  :to="{path: '/gqdj?hzy_id=9'}">外贸营销平台工具</el-breadcrumb-item>
                        <el-breadcrumb-item  :to="{path: '/gqdj?hzy_id=9'}">供求对接</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="details-wrap">
                    <div class="xuqiu-wrap">
                        <!-- <div class="xuqiu">
                            <div class="xuqiu-text">需求端需求端需求端</div>
                            <div class="xuqiu-p">专注于服务出口企业，打造N+1专注于服务出口企业，打造N+1专注于服务出口企业，打造N+1打造N+1专注于服务出口企业，打造N+1打造N+1</div>
                            <div class="xuqiu-bt">查看详情</div>
                        </div> -->

                        <div class="xuqiu" v-for="(item,index) in tuijianliebiao" :key="index" @click="goTo('supply_details',{id:item.id})">
                            <div class="xuqiu-text">{{item.name}}</div>
                            <div class="xuqiu-p" v-html="delLabel(item.content)"></div>
                            <div class="xuqiu-bt" @click="goTo('supply_details',{id:item.id})">查看详情</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
    </div>
</template>

<script>
    import { getplates ,getplatexts,getcountrys} from "../../../api/index"
    export default {
        data() {
            return {
                id:'', 
                listData:[],
                tuijianliebiao:[]             
            }
        },
        created() {
            this.id=this.$route.query.id    
            this.getplates()     
            this.getplatexts()   
        },
        methods: {    
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            // 去除富文本所有标签
            delLabel(input){
                if (input!=null) {
                    return input.replace(/<\/?[^>]*>/g, ""); 
                }
            },
            goTab(id){
                this.id=id
                this.getplatexts()
            },
            // 推荐分类
            getplates(){
                getplates({
                    p_id:9,
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.listData=res.data
                        // for (let i = 0; i < this.listData.length; i++) {
                        //     this.getplatexts(this.listData[i].id)                            
                        // } 
                    }
                })
            },
            //推荐分类下面的列表
            getplatexts(){
                getplatexts({
                    plate_id:this.id
                }).then((res)=>{
                    console.log(res)
                    if (res.code==200) {
                        this.tuijianliebiao=res.data.data
                    }

                })

            },     
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{height:25px;display: flex;align-items: center;}
    .hzycenter-text{
        height:25px;display: flex;align-items: center;
        p{width: 3px;height: 25px;background: #0071DB;}
        span{font-size: 18px;color: #0071DB;margin-left: 10px;}
    }
    .conter{
        margin: auto;margin-bottom:140px;margin-top: 45px;display: flex;
        .conter-left{
            width:230px;flex-shrink: 0;margin-right: 15px;
            .nav-list{
                border: 1px solid #CDCDCD;margin-top:15px;
                .nav-item{cursor: pointer;
                    height: 50px;padding: 0 20px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #CDCDCD;
                    div{font-size: 16px;color: #3D3D3D;}
                    p{color: #0071DB;font-size: 22px;font-weight: bold;}
                    &:hover{
                        div{color: #0071DB;}
                    }
                    &:last-of-type{border-bottom: none;}
                }
                .active{color: #0071DB !important;}

            }
        }
        .conter-right{
            flex: 1;
            .details-wrap{
                background: #fff;margin-top: 15px;padding: 50px 30px;
               .xuqiu-wrap{
                    display: flex;
                    flex-wrap: wrap;
                    .xuqiu{
                        width: 23%;
                        margin-right: 2.6666%;
                        background: #F7F7F7;
                        box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.16);
                        border: 1px solid #E8E8E8;
                        border-top: 3px solid #0071DB;
                        box-sizing: border-box;
                        margin-bottom: 30px;
                        &:nth-child(4n+0){margin-right: 0;}
                        .xuqiu-text{
                            padding: 35px 0 20px 0;
                            text-align: center;
                            font-size: 22px;
                            font-weight: 300;
                        }
                        .xuqiu-p{
                            height: 72px;
                            padding: 0 18px;
                            color: #666666;
                            line-height: 24px;
                            font-size: 16px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                        .xuqiu-bt{
                            width: 125px;
                            height: 35px;
                            line-height: 35px;
                            text-align: center;
                            border: 2px solid #FE6D00;
                            background: #fff;
                            font-size: 16px;
                            margin: 35px 18px 21px 18px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        
    }

</style>